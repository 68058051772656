<template>
  <v-container>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-file-document-box-multiple-outline" :title="$t('edit_user')">
          <users-form title="add_user" :user="user" @validated="updateUser(user)" :buttons="true"></users-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UsersForm from "@/components/user/Form";
export default {
  name: "EditUserForm",
  components: { "users-form": UsersForm },
  mounted() {
    this.fetchUser();
  },
  data() {
    return {
      user: null
    };
  },
  methods: {
    ...mapActions("users", ["getUser", "editUser"]),
    fetchUser() {
      this.getUser(this.$route.params.id).then(response => {
        this.user = response;
      });
    },
    updateUser(user) {
      this.editUser({ user_id: this.$route.params.id, user: this.user }).then(
        response => {
          if (response) {
            this.errors.clear();
            this.$alert(this.$t("user_edit_success"), '', 'success');
          }
          this.$router.back();
        }
      );
    }
  }
};
</script>