<template>
  <form
    action
    data-vv-scope="user-form"
    @submit.prevent="validateForm('user-form')"
  >
    <base-material-alert v-if="userProcessed" color="success" dark>{{
      $t(text_processed)
    }}</base-material-alert>

    <v-row v-if="user">
      <v-col class="py-0" cols="12" sm="6" md="">
        <label>{{ $t("first_name") }}</label>

        <v-text-field
          outlined
          hide-details
          dense
          v-model="user.first_name"
          data-vv-validate-on="blur"
          v-validate="skip_validate ? 'min:2' : 'required|min:2'"
          :error-messages="errors.collect('user-form.first_name')"
          :disabled="disabled"
          data-vv-name="first_name"
          color="primary"
        />
      </v-col>

      <v-col class="py-0" cols="12" sm="6" md="">
        <label>{{ $t("last_name") }}</label>

        <v-text-field
          outlined
          hide-details
          dense
          v-model="user.last_name"
          data-vv-validate-on="blur"
          v-validate="skip_validate ? 'min:2' : 'required|min:2'"
          :error-messages="errors.collect('user-form.last_name')"
          :disabled="disabled"
          color="primary"
          data-vv-name="last_name"
        />
      </v-col>
      <v-col class="py-0" cols="12" sm="6" md="">
        <label>{{ $t("email") }}</label>

        <v-text-field
          outlined
          hide-details
          dense
          v-model="user.email"
          data-vv-validate-on="blur"
          v-validate="view || skip_validate ? 'email' : 'required|email'"
          :error-messages="errors.collect('user-form.email')"
          :disabled="disabled"
          color="primary"
          data-vv-name="email"
          v-bind:class="{ 'error--text': email_error }"
          @blur="checkEmail"
          :hint="email_error ? 'Email en uso' : ''"
        />
      </v-col>
      <v-col class="py-0" cols="12" sm="6" md="">
        <label>{{ $t("phone") }}</label>
        <v-text-field
          v-if="!relations || user.role_id === 8"
          outlined
          hide-details
          dense
          v-model="user.phone"
          data-vv-validate-on="blur"
          :disabled="disabled"
          v-validate="''"
          type="number"
          v-on:keypress="isNumber($event)"
          :error-messages="errors.collect('user-form.phone')"
          color="primary"
          data-vv-name="phone"
        />
        <v-text-field
          v-else
          outlined
          hide-details
          dense
          v-model="user.phone"
          data-vv-validate-on="blur"
          :disabled="disabled"
          v-validate="''"
          type="number"
          v-on:keypress="isNumber($event)"
          :error-messages="errors.collect('user-form.phone')"
          color="primary"
          data-vv-name="phone"
        />
      </v-col>
      <slot name="row1"></slot>
    </v-row>

    <v-row>
      <v-col
        class="py-0"
        cols="12"
        sm="6"
        md="3"
        v-if="!isCustomer && password !== false"
      >
        <label>{{ $t("password") }}</label>
        <v-text-field
          outlined
          hide-details
          :disabled="disabled"
          dense
          v-model="user.password"
          data-vv-validate-on="blur"
          v-validate="skip_validate ? '' : 'required'"
          :error-messages="errors.collect('user-form.password')"
          color="primary"
          data-vv-name="password"
          :append-icon="show.password ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="show.password = !show.password"
          :type="show.password ? 'text' : 'password'"
        />
      </v-col>
      <v-col
        class="py-0"
        sm="6"
        cols="12"
        md="3"
        v-if="!isCustomer && password !== false"
      >
        <label>{{ $t("password2") }}</label>
        <v-text-field
          outlined
          hide-details
          :disabled="disabled"
          dense
          v-model="user.password_repeat"
          data-vv-validate-on="blur"
          v-validate="
            skip_validate
              ? 'is:' + user.password
              : 'required|is:' + user.password
          "
          :error-messages="errors.collect('user-form.password_repeat')"
          data-vv-name="password_repeat"
          color="primary"
          :append-icon="show.password_repeat ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="show.password_repeat = !show.password_repeat"
          :type="show.password_repeat ? 'text' : 'password'"
          :append-outer-icon="
            user.password_repeat
              ? user.password_repeat == user.password
                ? 'mdi-check'
                : 'mdi-close'
              : ''
          "
        />
      </v-col>
      <template v-if="roles">
        <v-col class="py-0" cols="12" sm="6" md="3">
          <label>{{ $tc("role") }}</label>
          <v-select
            :items="rolesList"
            v-model="user.role_id"
            outlined
            hide-details
            dense
            :disabled="disabled"
            data-vv-validate-on="blur"
            v-validate="skip_validate ? '' : 'required'"
            :error-messages="errors.collect('user-form.role_id')"
            data-vv-name="role_id"
          >
          </v-select>
        </v-col>
        <v-col v-if="$store.getters['auth/isStudio'] && studiosList.length > 1">
          <label>Estudios a los que tiene acceso</label>
          <v-select
            :items="studiosList"
            v-model="user.studios_ids"
            outlined
            hide-details
            dense
            :disabled="disabled"
            data-vv-validate-on="blur"
            v-validate="skip_validate ? '' : 'required'"
            :error-messages="errors.collect('user-form.studios_ids')"
            data-vv-name="studios_ids"
            multiple
          ></v-select>
        </v-col>
      </template>
      <slot name="row2"></slot>
    </v-row>
    <v-row v-if="show_cash_registers">
      <v-col cols="12" md="3">
        <label>¿Puede ver otras cajas?</label>
        <v-select
          outlined
          dense
          hide-details
          v-model="user.can_see_cash_registers"
          :items="$store.getters['app/yesNo']"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <label>Cajas</label>
        <v-select
          outlined
          dense
          hide-details
          v-model="user.access_to_cash_registers"
          :items="cashRegisters"
          item-text="name"
          item-value="id"
          multiple
          :disabled="user.can_see_cash_registers != true"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <label>¿Puede editar las cajas?</label>
        <v-select
          outlined
          dense
          hide-details
          v-model="user.can_edit_cash_registers"
          :items="$store.getters['app/yesNo']"
          :disabled="user.can_see_cash_registers != true"
        >
        </v-select>
      </v-col>
    </v-row>

    <div class="pa-3 text-right" v-if="buttons">
      <v-btn
        outlined
        hide-details
        style="height: 25px; width: 100px"
        elevation="0"
        @click="$router.back()"
      >
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        class="ml-1"
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
        type="submit"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FabButton from "@/components/ui/FabButton";
import constants from "@/constants";
import { mask } from "vue-the-mask";

export default {
  name: "UsersForm",
  components: {
    FabButton,
    PaymentLink: () => import("@/components/expense/PaymentLink"),
  },
  props: [
    "title",
    "user",
    "buttons",
    "password",
    "roles",
    "disabled",
    "edit",
    "view",
    "skip_validate",
    "show_cash_registers",
  ],
  data() {
    return {
      userProcessed: false,
      show: { password: false, password_repeat: false },
      email_error: false,
      relations: null,
      textEmpty: null,
      cashRegisters: null,
    };
  },
  mounted() {
    if (this.$store.getters["auth/isStudio"]) {
      setTimeout(() => {
        this.fetchTattooerStudioRelations();
        if (this.studiosList.length == 1 && this.roles) {
          this.user.studios_ids.push(this.studiosList[0].value);
        }
      }, 10);
      console.log(this.relations);
    }
    if (this.show_cash_registers) {
      this.fetchCashRegisters();
    }
    console.log(this.view);
  },
  directives: {
    mask,
  },
  computed: {
    isCustomer() {
      return this.user.role_id === constants.roles.CUSTOMER;
    },
    rolesList() {
      if (this.$store.getters["auth/isStudio"]) {
        return [
          {
            text: this.$tc("studio_admin"),
            value: constants.roles.STUDIO_ADMIN,
          },
          {
            text: this.$tc("studio_manager"),
            value: constants.roles.STUDIO_MANAGER,
          },
        ];
      }
      return [];
    },
    ...mapState("auth", ["users"]),
    studiosList() {
      return this.users.map((user) => {
        return {
          text: user.user.studio.studio_name,
          value: user.user.id,
        };
      });
    },
  },
  methods: {
    ...mapActions("cash_register", [
      "getCashRegisters",
      "getDefaultCashRegister",
    ]),
    fetchCashRegisters() {
      this.getCashRegisters({ paginator: { itemPerPage: -1 } }).then(
        (response) => {
          console.log("cash registers", response);
          this.cashRegisters = response.data;
        }
      );
      /*
      this.getDefaultCashRegister().then((response) => {
        console.log("default cash register", response);
        this.$store.commit(
          "expenses/SET_EXPENSES_CASH_REGISTER_ID",
          response.id
        );
      });
      */
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    ...mapActions("users", ["checkUserEmail"]),
    async checkEmail() {
      if (this.user.role_id === 2)
        this.checkUserEmail({ email: this.user.email, id: this.user.id }).then(
          (data) => {
            this.email_error = data.exist;
          }
        );
      else this.email_error = false;
    },
    ...mapActions("tattooers", ["getTattooerStudioRelations"]),
    fetchTattooerStudioRelations() {
      this.loading = true;
      console.log("fetch", this.user);
      this.getTattooerStudioRelations({
        tattooer_id: this.user.id,
        studio_id: this.$store.state.auth.user.id,
      }).then((tatStuRel) => {
        //console.log("inside fetchhh");

        //console.log("%ca", "color: #e50000", tatStuRel);
        this.relations = tatStuRel;

        let today = new Date().getTime();
        let a = tatStuRel.find((x) => {
          return (
            new Date(x.from).getTime() <= today &&
            (x.to === null || new Date(x.to).getTime() > today)
          );
        });
        if (a) {
          this.active = true;
        }
        console.log(this.relations);
        this.loading = false;
      });
    },
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      await this.checkEmail();
      let result = await this.$validator.validateAll(scope);
      if (result && !this.email_error) {
        this.$emit("validated", this.user);
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="sass" scoped></style>
