<template>
  <v-tooltip :left="tooltipPosition === 'left'">
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        fixed
        dark
        fab
        bottom
        right
        color="pink"
        v-on="on"
        @click="$emit('fireClick')"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ $t(tooltip) }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'FabButton',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
      tooltipPosition: {
        type: String,
        default: 'left',
      },
    },
  }
</script>
